<template>
  <div>
      <template v-if="query.categoryId===4&&fixQueue">
        <h4 class="p-b-5">选择库存</h4>
        <Row class="p-b-5">
            <i-col span="24">
                <div class="p-l-10 p-r-10 m-b-2" :class="(1 === purchaseType)?'workplatform-radio-button-active':'workplatform-radio-button'"
                  @click="changePurchaseType(1)">可采购库存</div>
                <div class="p-l-10 p-r-10 m-b-2" :class="(2 === purchaseType)?'workplatform-radio-button-active':'workplatform-radio-button'"
                  @click="changePurchaseType(2)">排队库存</div>
            </i-col>
        </Row>
        <p v-if="showTips&&purchaseType===2" class="text-orange m-t-5">
          提示：您选择的发布档期为多个，因释放和占用时长不一致故无法排队，请选择当个档期进行操作！
        </p>
      </template>

      <h4 class="p-b-5">资源清单</h4>
      <Row class="p-b-5">
        <i-col span="18">
          <i-input size="small" v-model="query.keyword" clearable placeholder="单品名称关键字"></i-input>
        </i-col>
        <i-col span="6">
          <Button size="small" icon="ios-search" type="primary" @click="changePage(1)">搜索</Button>
        </i-col>
      </Row>

      <!-- 主题媒介 -->
      <template v-if="query.categoryId===4">
        <Row class="table-title p-t-2">
            <i-col span="8">单品名称</i-col>
            <i-col span="6" class="text-center">所属产品</i-col>
            <i-col span="5" class="text-center">发布费(4周)</i-col>
            <i-col span="5" class="text-right p-r-2">操作</i-col>
        </Row>
        <div style="position: relative;">
            <Row v-for="(resource,index) in tableData" :key="index" class="p-t-2" :class="(index%2 === 0)?'table-row-1':'table-row-2'">
                <i-col span="8">{{resource.productskuName}}</i-col>
                <i-col span="6" class="text-center">{{resource.productName}}</i-col>
                <i-col span="5" class="text-center">{{formatMoney(resource.usePrice*28)}}</i-col>
                <i-col span="5" class="text-right">
                  <template v-if="purchaseType===1">
                    <a @click="handleAddResource(resource)">采购</a>
                    <a class="m-l-5" @click="handleShowDetail(resource)">详情</a>
                  </template>
                  <template v-else>
                    <a class="m-r-2" @click="handleAddQueue(resource)">去排队</a>
                  </template>
                </i-col>
            </Row>

            <div class="paging_style">
                <Page size="small" :total="total" :page-size="query.pageSize" :current="query.pageNumber" show-total  @on-change="changePage"></Page>
            </div>
            <Spin fix size="large" v-if="loadingResource">
                <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                <div>资源加载中...</div>
            </Spin>
        </div>
      </template>
      <!-- 套装采购 -->
      <template v-else-if="query.categoryId===7">
        <Row class="table-title p-t-2">
          <i-col span="12" class="p-l-5">单品名称</i-col>
          <i-col span="6">发布费(4周)</i-col>
          <i-col span="6" class="text-center">操作</i-col>
        </Row>
        <div style="position: relative;">
            <Row v-for="(resource,index) in tableData" :key="index" class="p-t-2" :class="(index%2 === 0)?'table-row-1':'table-row-2'">
                <i-col span="12" class="p-l-5">{{resource.productskuName}}</i-col>
                <i-col span="6">{{formatMoney(resource.usePrice*28)}}</i-col>
                <i-col span="6" class="text-center">
                    <Button type="success" size="small" @click="handleAddResource(resource)">一键加入</Button>
                </i-col>
                <i-col span="24" class="remark">
                    包含资源：<span class="m-l-5" v-for="dto in resource.customDescDTOList" :key="dto.productskuId"
                        >{{dto.productskuName}}({{dto.quantity}})</span>
                </i-col>
            </Row>

            <div class="paging_style">
                <Page size="small" :total="total" :page-size="query.pageSize" show-total  @on-change="changePage"></Page>
            </div>
            <Spin fix size="large" v-if="loadingResource">
              <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
              <div>资源生成中...</div>
            </Spin>
        </div>
      </template>

      <Modal v-model="showResourcePostion" width="1200" footer-hide :styles="{top: '20px'}">
          <resourceThemeInfo v-if="showResourcePostion" :resourceId="chooseProductSkuId"/>
      </Modal>

      <Modal v-model="showElectricRule" width="600" footer-hide>
        <p class="remark m-b-10">tips: 当前产品中包含电子屏，需要设置投放规格和投放次数</p>
        <h4 class="p-b-5">投放规格：</h4>
        <Row>
          <i-col span="24" class="p-b-5">
            <RadioGroup v-model="selectDuration" type="button" size="small" >
              <Radio v-for="(item,index) in electricDurations" :key="index" :label="item">{{ item }} 秒</Radio>
            </RadioGroup>
          </i-col>
        </Row>
        <h4 class="p-b-5">投放次数：</h4>
        <Row>
          <i-col span="24" class="p-b-5">
            <RadioGroup v-model="selectPeriod" type="button" size="small" >
              <Radio v-for="(period,index) in electricPeriods" :key="index" :label="period">{{ period }} 次/天</Radio>
            </RadioGroup>
          </i-col>
        </Row>
        <p class="m-t-20">
          <Button type="success" :disabled="isPurchaseElectric" @click="handleAddResourceByElectric">确认采购</Button>
        </p>
      </Modal>
  </div>
</template>

<script>
import resourceThemeInfo from '@/components/stock/resourceComponent/ThemeInfo'

import { getProductAndSkuList } from '@/api/product/productsku'
import { getElectricRule } from '@/api/product/electricProducts'
import { adddtProductSkus } from '@/api/order/orderitem'
import { addSkuQueue } from '@/api/order/orderqueue'

import { toMoney } from '@/utils/wnumb_own'

export default {
  components: {
    resourceThemeInfo
  },
  data () {
    return {
      query: {
        orderId: -1,
        assetIds: '', // 资产主键
        categoryId: null, // 产品类别主键
        productId: null,
        schedules: [], // 订单采购档期
        pageNumber: 1, // 当前页
        keyword: '',
        pageSize: 10, // 页大小
        queue: false // 可排队（只有主题媒介才有效）
      },
      total: 0,
      tableData: [],
      loadingResource: false, // 载入表格数据
      showResourcePostion: false,
      chooseProductSkuId: 0, // 选中的主题媒介单品ID，用于弹窗显示位置详情
      defaultProduct: null,

      electricDurations: [],
      electricPeriods: [],
      selectDuration: null,
      selectPeriod: null,

      fixQueue: this.$store.getters.token.publisherSetting.fixQueue, // 是否显示排队信息
      publisherId: this.$store.getters.token.userInfo.publisherId,
      purchaseType: 1, // 采购类型
      showTips: false, // 显示多档期提示

      showElectricRule: false,
      purchaseResource: null
    }
  },
  computed: {
    isPurchaseElectric () {
      return !(this.selectDuration && this.selectPeriod)
    }
  },
  methods: {
    initPageData (searchBean) {
      this.query.orderId = searchBean.orderId
      this.query.assetIds = JSON.stringify(searchBean.assetIds) // 资产主键
      this.query.categoryId = searchBean.categoryId // 产品类别主键
      if (searchBean.productId !== -1 && searchBean.categoryId !== 7) {
        this.query.productId = searchBean.productId
      } else {
        this.query.productId = undefined
      }
      this.query.schedules = JSON.stringify(searchBean.schedules) // 订单采购档期
      this.query.pageNumber = 1
      this.query.keyword = ''
      this.query.queue = false
      this.purchaseType = 1
      this.showTips = searchBean.schedules.length > 1

      this.defaultProduct = {
        startDate: searchBean.schedules[0].startDate,
        endDate: searchBean.schedules[0].endDate
      }

      this.SeachResource()
      // 更新地图显示站点和线路
      const params = {
        data: [],
        checkedSchedules: searchBean.schedules,
        assetIds: searchBean.assetIds,
        condition: {}
      }
      this.$emit('on-updated-map', 'full', params)
    },
    SeachResource () {
      if (this.query.queue && this.showTips) {
        this.tableData = []
        this.total = 0
      } else {
        // 开始查询数据
        this.loadingResource = true
        getProductAndSkuList(this.query).then(res => {
          // 防止无限排队后，当前页数据为空
          if (this.query.pageNumber > 1 && res.list.length === 0) {
            this.query.pageNumber -= 1
            this.SeachResource()
          }

          this.tableData = res.list
          this.total = res.totalRow
          this.loadingResource = false
        }).catch(() => { this.loadingResource = false })
      }
    },
    getElectronicRules (productId) { // 获取电子屏采购规则
      const queryData = {
        productId: productId,
        publisherId: this.publisherId
      }

      getElectricRule(queryData).then(res => {
        this.electricDurations = res.durations.split(',').map(x => { return parseInt(x) })
        this.selectDuration = this.electricDurations.length > 0 ? this.electricDurations[0] : [null]

        this.electricPeriods = res.periods.split(',').map(x => { return parseInt(x) })
        this.selectPeriod = this.electricPeriods.length > 0 ? this.electricPeriods[0] : null

        // 给store赋值，地图采购会使用到
        this.$store.commit('set_order_purchase_duration', this.selectDuration)
        this.$store.commit('set_order_purchase_period', this.selectPeriod)
      })
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.SeachResource()
    },
    handleAddResource (productSku) {
      if (this.loadingResource) { return }
      this.selectDuration = null
      this.selectPeriod = null
      this.purchaseResource = productSku

      // 如果包含电子屏采购规则， 那么需要弹窗来要求输入采购规格和次数
      if (productSku.hasElectric) {
        this.getElectronicRules(productSku.productId)
        this.showElectricRule = true
      } else {
        this.loadingResource = true
        this.beginAddResource()
      }
    },
    handleAddResourceByElectric () {
      this.showElectricRule = false
      this.beginAddResource()
    },
    beginAddResource () {
      const GenerationCondition = {
        assetIds: this.query.assetIds, // 线路集合
        orderId: this.query.orderId,
        schedules: this.query.schedules,
        productskus: JSON.stringify([{ productskuId: this.purchaseResource.productskuId, quantity: 1 }]),

        duration: this.selectDuration,
        period: this.selectPeriod
      }

      adddtProductSkus(GenerationCondition).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({
            title: '资源添加成功!',
            desc: '请在页面右侧【采购清单】中查看购买详情！'
          })
          // 更新采购清单列表
          this.SeachResource()
          // 默认选中编辑状态
          this.defaultProduct.productId = this.purchaseResource.productId
          this.$emit('on-update-order', this.defaultProduct)
        }
        this.loadingResource = false
      }).catch(() => { this.loadingResource = false })
    },
    handleAddQueue (productSku) {
      if (this.loadingResource) { return }

      this.loadingResource = true
      const GenerationCondition = {
        startDate: this.defaultProduct.startDate,
        orderId: this.query.orderId,
        endDate: this.defaultProduct.endDate,
        skuId: productSku.productskuId,

        duration: this.selectDuration,
        period: this.selectPeriod
      }

      addSkuQueue(GenerationCondition).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({
            title: '主题媒介排队成功!',
            desc: '请在页面右侧【查看排队清单】中查看详情！'
          })
          // 更新采购清单列表
          this.SeachResource()
        }
        this.loadingResource = false
      }).catch(() => { this.loadingResource = false })
    },
    handleShowDetail (resource) {
      this.chooseProductSkuId = resource.productskuId
      this.showResourcePostion = true
    },
    formatMoney (number) {
      return toMoney(number)
    },
    reload () { // 用于外部组建改变，而引发该控件刷新数据
      this.SeachResource()
    },
    changePurchaseType (param) {
      if (param === this.purchaseType) { return }

      this.purchaseType = param
      this.query.queue = (this.purchaseType === 2)
      this.query.pageNumber = 1
      this.query.keyword = ''

      this.SeachResource()
    }
  },
  watch: {
    selectDuration (val) {
      this.$store.commit('set_order_purchase_duration', val)
    },
    selectPeriod (val) {
      this.$store.commit('set_order_purchase_period', val)
    }
  }
}
</script>
